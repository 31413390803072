import { Heading, Carousel } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React from 'react';

import { DestinationInspiration } from './DestinationInspiration';
import { DestinationInspiration as DestinationInspirationType } from '@AuroraTypes';
import { ClassNameProps } from '@ComponentProps';
import { SectionContainer } from '@Components/SectionContainer';
import { useProductImpressionTracking } from '@Tracking';

interface DestinationInspirationsProps extends ClassNameProps {
  destinationInspirations: DestinationInspirationType[];
}

export const DestinationInspirations: React.FC<DestinationInspirationsProps> = ({
  className,
  destinationInspirations,
}) => {
  const { t } = useTranslation();

  const impressionRef = useProductImpressionTracking(
    { list: 'PopularHolidays' },
    !destinationInspirations?.length,
  );

  if (!destinationInspirations?.length) {
    return null;
  }

  return (
    <SectionContainer
      ref={impressionRef}
      data-id="destination-inspirations"
      className={className}
    >
      <Heading
        variant="largebold"
        as="h2"
        sx={{ marginBottom: 's' }}
      >
        {t('popularHolidays')}
      </Heading>
      <Carousel>
        {destinationInspirations.map((item, index) => (
          <DestinationInspiration
            key={index}
            {...item}
          />
        ))}
      </Carousel>
    </SectionContainer>
  );
};
