import { Heading, Paragraph, Image, Link } from '@loveholidays/design-system';
import React from 'react';

import { DestinationInspiration as DestinationInspirationType } from '@AuroraTypes';
import { ClassNameProps } from '@ComponentProps';
import { WithClickTracking } from '@Core/tracking/WithClickTracking';

export type DestinationInspirationProps = ClassNameProps & DestinationInspirationType;

export const DestinationInspiration: React.FC<DestinationInspirationProps> = ({
  className,
  searchUrl,
  customUrl,
  title,
  image,
  description,
}) => (
  <WithClickTracking action="destination-inspiration">
    <Link
      asAnchor={false}
      internal
      href={customUrl ?? searchUrl}
      data-id="destination-inspiration"
      className={className}
      sx={{
        display: 'block',
        textDecoration: 'none',
        color: 'textDefault',
        '&:hover': {
          color: 'textDefault',
          h3: {
            textDecoration: 'underline',
          },
          img: {
            transform: 'scale(1.04)',
          },
        },
        '&:visited': {
          color: 'textDefault',
        },
      }}
    >
      <div
        sx={{
          marginBottom: '2xs',
        }}
      >
        <Image
          lazy
          src={image.url}
          alt={image.description}
          width={[270, 332, 384]}
          height={240}
          sx={{
            borderRadius: '_chipped',
            overflow: 'hidden',
            maskImage: '-webkit-radial-gradient(white, black)',
            img: {
              transition: 'transform 400ms ease-in-out',
            },
          }}
        />
      </div>
      <Heading
        variant="largebold"
        as="h3"
        sx={{ marginBottom: '2xs' }}
      >
        {title}
      </Heading>
      <Paragraph
        variant="small"
        sx={{
          color: 'textDimmedmedium',
        }}
      >
        {description}
      </Paragraph>
    </Link>
  </WithClickTracking>
);
